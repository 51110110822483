import { AnalyticsDbModel } from '@data-models/analyctis-db.model';
import { BusinessHoursDbModel } from '@data-models/business-hours-db.model';
import { CountryDbModel } from '@data-models/country-db.model';
import { FeaturesDbModel } from '@data-models/features-db.model';
import { LanguageDbModel } from '@data-models/language-db.model';
import { SubscriptionDbModel } from '@data-models/subscription-db.model';
import { WidgetBookingInfoDbModel } from '@data-models/widget-booking-info-db.model';
import { WorkerDbModel } from '@data-models/worker-db.model';

export enum TokenType {
  internalWidget = 'internal_widget',
  publicWidget = 'public_widget',
}

export class PartnerDbModel {
  activate_mobile_number_verification_feature: number;
  adobe_launch_tracking_url: string;
  allow_multiple_services_bookings: number;
  analytics: AnalyticsDbModel;
  appointment_booking_enabled: number;
  appointment_booking_info: string;
  appointment_lead_time: number;
  ask_for_customer_notification_preference: number;
  automatic_store_zip_search: number;
  booking_interval: number;
  booking_name: string;
  booking_page_background_color: string;
  booking_page_background_logo: string;
  booking_page_border_radius: string;
  booking_page_box_shadow: string;
  booking_page_hide_partner_address: number;
  booking_page_hide_switcher: number;
  booking_page_primary_color: string;
  booking_page_ui_mode: string;
  bookingwidget_enabled: number;
  business_hours: BusinessHoursDbModel[];
  city: string;
  country_id: number;
  country: CountryDbModel;
  courtesy_form: string;
  currency: string;
  disable_automatic_search: number;
  egal_wer_show: number;
  email: string;
  enable_services_search: number;
  enable_store_postcode_feature: number;
  event_booking_enabled: number;
  event_lead_time: number;
  event_slot_booking_info: string;
  favicon_logo: string;
  features: FeaturesDbModel[];
  future_booking_threshold_appointments: number;
  future_booking_threshold_events: number;
  google_api_key: string;
  group_appointments_filter_behaviour: string;
  has_active_subscription = true;
  hide_back_on_bookingsite: number;
  hide_email_on_bookingsite: number;
  hide_login_on_bookingsite: number;
  hide_mail_resource: number;
  hide_mobile_on_bookingsite: number;
  hide_resource_step: number;
  hide_website_on_bookingsite: number;
  hide_widget_cart_functionality_message: number;
  ical_hash: string;
  id: number;
  internal_widget_email_required: number;
  internal_widget_email_shown: number;
  is_business: number;
  is_cart_supported: number;
  is_custom_css_bookingpage_enabled: number;
  is_group_appointment_category_feature_enabled: number;
  is_group_appointment_store_feature_enabled: number;
  json_web_tokens: { token: string };
  landingpage_header_background_color: string;
  landingpage_logo: string;
  landingpage_tab_content: string;
  language_id: number;
  language: LanguageDbModel;
  logo: string;
  micro_partner_description: string;
  mobile: string;
  name: string;
  phone: string;
  resource_booking_enabled: number;
  show_address_on_widget: number;
  show_distance_in_stores: number;
  show_message_on_widget: number;
  show_mobile_of_worker_on_landingpage: number;
  show_phone_on_widget: number;
  show_salutation_in_widget: number;
  show_stores_as_dropdown: number;
  street: string;
  subscription: SubscriptionDbModel;
  supported_widget_languages: string[];
  token: string;
  url: string;
  uuid: string;
  widget_appointment_service_category_status: number;
  widget_background_color: string;
  widget_booking_info: WidgetBookingInfoDbModel;
  widget_disable_search_all_resource_button: number;
  widget_email_required: number;
  widget_email_shown: number;
  widget_footer_background_color: string;
  widget_footer_button_color: string;
  widget_header_active_color: string;
  widget_header_background_color: string;
  widget_hide_resource_step_for_default_workers: number;
  widget_show_all_stores_card: number;
  widget_show_free_slots_in_overview: number;
  widget_show_lead_creation_button: number;
  widget_text_color: string;
  worker?: WorkerDbModel;
  zip: string;
}
